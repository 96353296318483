import { SupportedLanguages } from "./config";

export type LocalizePathElement = Record<SupportedLanguages, string>;

export function getBrowserLocaleCode(localeCode: SupportedLanguages) {
  // Please use lowercase values
  const browserLocaleCodes: Partial<LocalizePathElement> = {
    [SupportedLanguages.zhHant]: "zh-tw",
  };
  return browserLocaleCodes[localeCode] ?? localeCode;
}

export const localizedPageStaticPathsMap: Record<string, LocalizePathElement> = {
  "case-history": {
    [SupportedLanguages.en]: "case-history",
    [SupportedLanguages.it]: "case-history",
    [SupportedLanguages.de]: "case-history",
    [SupportedLanguages.fr]: "case-history",
    [SupportedLanguages.es]: "case-history",
    [SupportedLanguages.zh]: "case-history",
    [SupportedLanguages.zhHant]: "case-history",
  },
  company: {
    [SupportedLanguages.en]: "company",
    [SupportedLanguages.it]: "azienda",
    [SupportedLanguages.de]: "unternehmen",
    [SupportedLanguages.fr]: "agence",
    [SupportedLanguages.es]: "agencia",
    [SupportedLanguages.zh]: "company",
    [SupportedLanguages.zhHant]: "company",
  },
  contacts: {
    [SupportedLanguages.en]: "contacts",
    [SupportedLanguages.it]: "contatti",
    [SupportedLanguages.de]: "kontakt",
    [SupportedLanguages.fr]: "contacts",
    [SupportedLanguages.es]: "contactos",
    [SupportedLanguages.zh]: "contacts",
    [SupportedLanguages.zhHant]: "contacts",
  },
  materials: {
    [SupportedLanguages.en]: "materials",
    [SupportedLanguages.it]: "materiali",
    [SupportedLanguages.de]: "materialien",
    [SupportedLanguages.fr]: "matériaux",
    [SupportedLanguages.es]: "materiales",
    [SupportedLanguages.zh]: "materials",
    [SupportedLanguages.zhHant]: "materials",
  },
  "product-families": {
    [SupportedLanguages.en]: "product-families",
    [SupportedLanguages.it]: "famiglie-prodotti",
    [SupportedLanguages.de]: "produkt-familien",
    [SupportedLanguages.fr]: "familles-de-produits",
    [SupportedLanguages.es]: "familias-de-producos",
    [SupportedLanguages.zh]: "product-families",
    [SupportedLanguages.zhHant]: "product-families",
  },
  "news-and-events": {
    [SupportedLanguages.en]: "news-and-events",
    [SupportedLanguages.it]: "news-ed-eventi",
    [SupportedLanguages.de]: "nachrichten-und-events",
    [SupportedLanguages.fr]: "nouvelles-et-événements",
    [SupportedLanguages.es]: "noticias-y-eventos",
    [SupportedLanguages.zh]: "news-and-events",
    [SupportedLanguages.zhHant]: "news-and-events",
  },
  products: {
    [SupportedLanguages.en]: "products",
    [SupportedLanguages.it]: "prodotti",
    [SupportedLanguages.de]: "produkte",
    [SupportedLanguages.fr]: "produits",
    [SupportedLanguages.es]: "productos",
    [SupportedLanguages.zh]: "products",
    [SupportedLanguages.zhHant]: "products",
  },
  search: {
    [SupportedLanguages.en]: "search",
    [SupportedLanguages.it]: "cerca",
    [SupportedLanguages.de]: "suche",
    [SupportedLanguages.fr]: "recherche",
    [SupportedLanguages.es]: "buscar",
    [SupportedLanguages.zh]: "search",
    [SupportedLanguages.zhHant]: "search",
  },
  news: {
    [SupportedLanguages.en]: "news",
    [SupportedLanguages.it]: "news",
    [SupportedLanguages.de]: "nachrichten",
    [SupportedLanguages.fr]: "nouvelles",
    [SupportedLanguages.es]: "noticias",
    [SupportedLanguages.zh]: "news",
    [SupportedLanguages.zhHant]: "news",
  },
  events: {
    [SupportedLanguages.en]: "events",
    [SupportedLanguages.it]: "eventi",
    [SupportedLanguages.de]: "veranstaltungen",
    [SupportedLanguages.fr]: "événements",
    [SupportedLanguages.es]: "eventos",
    [SupportedLanguages.zh]: "events",
    [SupportedLanguages.zhHant]: "events",
  },
  whistleblowing: {
    [SupportedLanguages.en]: "whistleblowing",
    [SupportedLanguages.it]: "whistleblowing",
    [SupportedLanguages.de]: "whistleblowing",
    [SupportedLanguages.fr]: "whistleblowing",
    [SupportedLanguages.es]: "whistleblowing",
    [SupportedLanguages.zh]: "whistleblowing",
    [SupportedLanguages.zhHant]: "whistleblowing",
  },
};

export type LocalizePagesKey = keyof typeof localizedPageStaticPathsMap;
