exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-case-history-tsx": () => import("./../../../src/pages/case-history.tsx" /* webpackChunkName: "component---src-pages-case-history-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-materials-tsx": () => import("./../../../src/pages/materials.tsx" /* webpackChunkName: "component---src-pages-materials-tsx" */),
  "component---src-pages-product-families-tsx": () => import("./../../../src/pages/product-families.tsx" /* webpackChunkName: "component---src-pages-product-families-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-whistleblowing-tsx": () => import("./../../../src/pages/whistleblowing.tsx" /* webpackChunkName: "component---src-pages-whistleblowing-tsx" */),
  "component---src-templates-event-detail-tsx": () => import("./../../../src/templates/event-detail.tsx" /* webpackChunkName: "component---src-templates-event-detail-tsx" */),
  "component---src-templates-material-detail-tsx": () => import("./../../../src/templates/material-detail.tsx" /* webpackChunkName: "component---src-templates-material-detail-tsx" */),
  "component---src-templates-news-and-events-tsx": () => import("./../../../src/templates/news-and-events.tsx" /* webpackChunkName: "component---src-templates-news-and-events-tsx" */),
  "component---src-templates-news-detail-tsx": () => import("./../../../src/templates/news-detail.tsx" /* webpackChunkName: "component---src-templates-news-detail-tsx" */),
  "component---src-templates-product-detail-tsx": () => import("./../../../src/templates/product-detail.tsx" /* webpackChunkName: "component---src-templates-product-detail-tsx" */),
  "component---src-templates-product-family-detail-tsx": () => import("./../../../src/templates/product-family-detail.tsx" /* webpackChunkName: "component---src-templates-product-family-detail-tsx" */),
  "component---src-templates-product-line-detail-tsx": () => import("./../../../src/templates/product-line-detail.tsx" /* webpackChunkName: "component---src-templates-product-line-detail-tsx" */),
  "component---src-templates-project-detail-tsx": () => import("./../../../src/templates/project-detail.tsx" /* webpackChunkName: "component---src-templates-project-detail-tsx" */)
}

