export enum SupportedLanguages {
  en = "en",
  it = "it",
  de = "de",
  fr = "fr",
  es = "es",
  zh = "zh",
  zhHant = "zh-Hant",
}

export interface LocaleConfig {
  localeCode: SupportedLanguages;
  localeLabel: string;
  nativeName: string;
}

export interface I18NConfig {
  locales: LocaleConfig[];
  defaultLocale: string;
}

const config: I18NConfig = {
  locales: [
    {
      localeCode: SupportedLanguages.it,
      localeLabel: SupportedLanguages.it,
      nativeName: "Italiano",
    },
    {
      localeCode: SupportedLanguages.en,
      localeLabel: SupportedLanguages.en,
      nativeName: "English",
    },
    {
      localeCode: SupportedLanguages.de,
      localeLabel: SupportedLanguages.de,
      nativeName: "Deutsch",
    },
    {
      localeCode: SupportedLanguages.es,
      localeLabel: SupportedLanguages.es,
      nativeName: "Español",
    },
    {
      localeCode: SupportedLanguages.fr,
      localeLabel: SupportedLanguages.fr,
      nativeName: "Français",
    },
    {
      localeCode: SupportedLanguages.zhHant,
      localeLabel: "tc",
      nativeName: "繁體中文",
    },
    {
      localeCode: SupportedLanguages.zh,
      localeLabel: "sc",
      nativeName: "简体中文",
    },
  ],
  defaultLocale: SupportedLanguages.en,
};

export const localesMap = config.locales.reduce<Record<string, string>>(
  (acc, { localeCode, nativeName }) => ({
    ...acc,
    [localeCode]: nativeName,
  }),
  {}
);

export default config;
