import { createContext, useContext } from "react";

import { SupportedLanguages } from "../../i18n/config";

interface LayoutContextData {
  language: string;
  defaultLanguage: string;
  currentPath: string;
}

export const defaultLayoutContextValue: LayoutContextData = {
  language: SupportedLanguages.en,
  defaultLanguage: SupportedLanguages.en,
  currentPath: "/",
};

export const LayoutContext = createContext(defaultLayoutContextValue);

export const useLayoutContext = () => useContext(LayoutContext);
